import { useEffect, useState } from 'react';
import IBButton from '../UIElements/IBButton';
import SuccessMessage from '../UIElements/SuccessMessage';
import TextField from '../UIElements/TextField';
import { createUseStyles } from 'react-jss';
import useRequestResource from '../hooks/useRequestResource';
import ErrorMessage from '../UIElements/ErrorMessage';

const useStyles = createUseStyles({
	passwordResetContainer: {
		width: '90%',
		margin: 'auto'
	},
	textFieldContainer: {
		width: '50%',
		margin: 'auto'
	}
});

function ResetPasswordView() {
	const [postPayload, setPostPayload] = useState<Record<any, any> | undefined>(undefined);
	const [success, setSuccess] = useState(false);
	const { response, loading, error } = useRequestResource({ appendingPath: '/users/reset-password', method: 'POST', body: postPayload, disableAuth: true });

	useEffect(() => {
		if (response && response.status === 201) {
			setSuccess(true);
			setPostPayload(undefined);
		}
	}, [response]);

	const classes = useStyles();

	const handleSubmit = () => {
		const username = (document.getElementById('resetPasswordUsernameField') as HTMLInputElement).value;
		const email = (document.getElementById('resetPasswordEmailField') as HTMLInputElement).value;
		setPostPayload({ email: email, username: username });
	}

	const getErrorMessage = () => {
		if (response && response.message) {
			return response.message;
		}
		return 'Something went wrong';
	}

	return (
		<div className={classes.passwordResetContainer}>
			<h1>Reset your password</h1>
			<div hidden={!success}>
				<SuccessMessage message='Your password has been successfully reset. You should receive an email shortly' />

			</div>
			<div hidden={!error}>
				<ErrorMessage message={getErrorMessage()} />
			</div>
			<p>
				You will receive an email at the email address registered with your account. This may take a few minutes to arrive. If you
				still do not see the email, please check your spam folder.
			</p>
			<div className={classes.textFieldContainer}>
				<TextField id='resetPasswordUsernameField' type='text' placeholder='Username' />
				<TextField id='resetPasswordEmailField' type='email' placeholder='Email' />
				<IBButton id='resetPasswordSubmitBtn' title='Submit' onClick={handleSubmit} loading={loading} />
			</div>
		</div>
	)
}

export default ResetPasswordView;