import { createUseStyles } from 'react-jss';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useRequestResource from '../../hooks/useRequestResource';
import { useEffect, useState } from 'react';
import { Discussion } from '../../models/Discussion';
import LoadingView from '../../UIElements/LoadingView';
import TextField from '../../UIElements/TextField';
import TextArea from '../../UIElements/TextArea';
import { Patch } from '../../models/Patch';
import { PatchBuilder } from '../../classes/PatchBuilder';
import IBButton from '../../UIElements/IBButton';
import ErrorMessage from '../../UIElements/ErrorMessage';

const useStyles = createUseStyles({
	createDiscussionContainer: {
		width: '90%',
		margin: 'auto',
		marginTop: '1em',
		paddingBottom: '1em',
		paddingTop: '1em',
		'& h2': {
			'@media screen and (max-width: 860px)': {
				fontSize: '1em'
			}
		}
	},
	nameBadge: {
		width: 'fit-content',
		marginTop: '2em'
	},
	inputSection: {
		display: 'block',
		width: '100%',
		height: '25vh',
		margin: 'auto',
		marginBottom: '2em'
	},
	endpointSection: {
		display: 'block',
		width: '90%',
		margin: 'auto',
		marginBottom: '2em'
	},
	endpointInputs: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	endpointLabel: {
		fontWeight: 'bold',
		marginRight: '0.5em'
	}
})


function EditDiscussionSubview() {
	const classes = useStyles();
	const navigate = useNavigate();
	const patchBuilder = new PatchBuilder();
	const [searchParams, setSearchParams] = useSearchParams();
	const { response: currentData, loading: currentDataLoading, error: currentDataError } = useRequestResource({ appendingPath: '/blooms?id=' + searchParams.get('id'), method: 'GET', relocateOnFail: true });
	const [discussion, setDiscussion] = useState<Discussion | null>(null);
	const [patchBody, setPatchBody] = useState<Patch[] | undefined>(undefined);
	const { response: patchResponse, loading: patchLoading, error: patchError } = useRequestResource({ appendingPath: '/blooms/' + searchParams.get('id'), method: 'PATCH', body: patchBody })

	useEffect(() => {
		try {
			setDiscussion(currentData.body as Discussion);
		} catch (error) {

		}
	}, [currentData]);

	useEffect(() => {
		if (patchResponse && patchResponse.status === 201) {
			navigate('/discussions');
		}
	}, [patchResponse]);

	const setPatch = (path: string, value: string) => {
		patchBuilder.addPatch(path, value)
	}

	const updateBloom = () => {
		const patch = patchBuilder.buildPatch();
		setPatchBody(patch);
	}

	return (
		<div className={classes.createDiscussionContainer}>
			{discussion &&
				<div>
					<div hidden={!patchError}>
						<ErrorMessage message={patchResponse && patchResponse.message ? patchResponse.message : 'Something went wrong...'} />
					</div>
					<h1>Edit Your Bloom</h1>
					<TextField type='text'
						placeholder='Bloom Title'
						id='createBloomTitleTextField'
						defaultValue={discussion.title}
						onChange={(val) => setPatch('/title', val)} />
					<div className={classes.inputSection}>
						<h2>Define the Problem</h2>
						<TextArea
							id='createBloomDescriptionTextField'
							placeholder='Write a description defining the problem you and your team are facing...'
							defaultValue={discussion.problemDescription}
							onChange={(val) => setPatch('/problemDescription', val)}
						/>
					</div>
					<div className={classes.inputSection}>
						<h2>Articulate Desired Collab Output</h2>
						<TextArea
							id='createBloomOutputTextField'
							placeholder='Succinctly describe what you would like the team to get out of this, so they can respond appropriately...'
							defaultValue={discussion.desiredOutput}
							onChange={(val) => setPatch('/desiredOutput', val)}
						/>
					</div>
					<div className={classes.inputSection}>
						<h2>Craft Question in Support of this Desired Output</h2>
						<TextArea
							id='createBloomQuestionTextField'
							placeholder='Write a question to guide your teams responses...'
							defaultValue={discussion.questionPrompt}
							onChange={(val) => setPatch('/questionPrompt', val)}
						/>
					</div>
					<div className={classes.endpointSection}>
						<h2>Define the session endpoints</h2>
						<div className={classes.endpointInputs}>
							<div>
								<label className={classes.endpointLabel} htmlFor='createDiscussionIterationsInput'>Iterations</label>
								<input type='number' defaultValue={discussion.maxIterations} id='createDiscussionIterationsInput'
									onChange={(evt) => setPatch('/maxIterations', evt.target.value)} />
							</div>
							<div>
								<label className={classes.endpointLabel} htmlFor='createDiscussionConsensusInput'>Consensus</label>
								<input type='number' defaultValue={discussion.targetConsensus} id='createDiscussionConsensusInput'
									onChange={(evt) => setPatch('/targetConsensus', evt.target.value)} />
							</div>
							<div>
								<label className={classes.endpointLabel} htmlFor='createDiscussionTimeInput'>Time (min)</label>
								<input type='number' defaultValue={discussion.maxTimeMin} id='createDiscussionTimeInput'
									onChange={(evt) => setPatch('/maxTimeMin', evt.target.value)} />
							</div>
						</div>
					</div>
					<IBButton title='Update Bloom' onClick={() => updateBloom()} id='createDiscussionSubmitBtn' loading={patchLoading} />
				</div>
			}
			<div hidden={!currentDataLoading}>
				<LoadingView />
			</div>
		</div>
	)
}

export default EditDiscussionSubview;