import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	nameBadge: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	nameBadgeIcon: {
		width: '75px',
		height: '75px',
		fontSize: '37.5px',
		lineHeight: '75px',
		borderRadius: '37.5px',
		backgroundColor: 'coral',
		textAlign: 'center',
		verticalAlign: 'middle',
		alignContent: 'center',
		fontWeight: 'bold'
	},
	nameBadgeName: {
		fontWeight: 'bold',
		marginLeft: '1em',
		fontSize: '20px'
	}
});

function NameBadgeView({ name }: { name: string }) {
	const classes = useStyles();

	return (
		<div className={classes.nameBadge}>
			<div className={classes.nameBadgeIcon}>{name.slice(0, 1).toUpperCase()}</div>
			<div className={classes.nameBadgeName}>{name}</div>
		</div>
	)
}

export default NameBadgeView;