import TextField from '../UIElements/TextField';

function ExistingCompanyRegistration() {
	return (
		<div>
			<h2>Great, just enter your unique company registration code below. It should have been sent in an email to you. If you don't have it, please contact your account administrator.</h2>
			<TextField id='companyRegistrationCodeInput' type='text' placeholder='Registration Code' />
		</div>
	)
}

export default ExistingCompanyRegistration;