import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	textArea: {
		backgroundColor: 'whitesmoke',
		overflowY: 'scroll',
		resize: 'none',
		width: '100%',
		height: '75%',
		padding: '10px',
		borderRadius: '10px',
		'&::-webkit-scrollbar': {
			width: '10px',
			borderRadius: '10px',
			backgroundColor: 'whitesmoke'
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '10px',
			backgroundColor: 'gray'
		},
		'&::-webkit-scrollbar-track': {
			backgroundColor: 'whitesmoke'
		}
	}
});

function TextArea({ placeholder, onChange, id, defaultValue }: { placeholder: string, onChange?: (text: string) => void, id: string, defaultValue?: string }) {
	const classes = useStyles();

	const handleOnChange = (e: any) => {
		if (onChange) {
			onChange(e.target.value);
		}
	}

	return (
		<textarea
			id={id}
			placeholder={placeholder}
			onChange={handleOnChange}
			className={classes.textArea}
			defaultValue={defaultValue}>

		</textarea>
	)

}

export default TextArea;