import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import RegisterUserContainer from './RegisterUserContainer';
import IBButton from '../UIElements/IBButton';
import CompantRegistrationType from './CompanyRegistrationType';
import ExistingCompanyRegistration from './ExistingCompanyRegistration';
import NewCompanyRegistration from './NewCompanyRegistration';
import { Registration } from './models/Registration';
import ErrorMessage from '../UIElements/ErrorMessage';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles({
	registerContainer: {
		width: '50%',
		margin: 'auto'
	},
	buttonContainer: {
		display: 'flex',
		flexBasis: 'column',
		justifyContent: 'space-evenly',
		marginTop: '2em'
	}
});

enum CurrentPage {
	User = 0,
	CompanySelect = 10,
	CompanyCode = 20,
	CompanyCreate = 30
}

enum RegistrationSelection {
	ExistingCompany = 0,
	NewCompany = 10
}

const getPreviousPage = (currentPage: CurrentPage) => {
	switch (currentPage) {
		case CurrentPage.User:
			return CurrentPage.User;
		case CurrentPage.CompanySelect:
			return CurrentPage.User;
		case CurrentPage.CompanyCode:
			return CurrentPage.CompanySelect;
		case CurrentPage.CompanyCreate:
			return CurrentPage.CompanySelect;
	}
}

const getNextPage = (currentPage: CurrentPage, registrationSelection: RegistrationSelection) => {
	switch (currentPage) {
		case CurrentPage.User:
			return CurrentPage.CompanySelect;
		case CurrentPage.CompanySelect:
			if (registrationSelection === RegistrationSelection.ExistingCompany) {
				return CurrentPage.CompanyCode;
			} else {
				return CurrentPage.CompanyCreate;
			}
		case CurrentPage.CompanyCode:
			return CurrentPage.CompanyCode;
		case CurrentPage.CompanyCreate:
			return CurrentPage.CompanyCreate;
	}
}

const prepareRegistrationData = (registrationSelection: RegistrationSelection) => {
	const email = (document.getElementById('registerEmailTextField') as HTMLInputElement).value;
	const username = (document.getElementById('registerUsernameTextField') as HTMLInputElement).value;
	const password = (document.getElementById('registerPasswordTextField') as HTMLInputElement).value;
	const firstName = (document.getElementById('registerFirstNameTextField') as HTMLInputElement).value;
	const lastName = (document.getElementById('registerLastNameTextField') as HTMLInputElement).value;
	const accessCode = (document.getElementById('companyRegistrationCodeInput') as HTMLInputElement).value;
	const companyName = (document.getElementById('companyNameInput') as HTMLInputElement).value;
	const companyIndustry = (document.getElementById('companyIndustryInput') as HTMLInputElement).value;
	const numEmployees = (document.getElementById('companyNumEmployeesInput') as HTMLInputElement).value;

	const registrationPayload: Registration = {
		email: email,
		username: username,
		password: password,
		firstName: firstName,
		lastName: lastName
	};
	if (registrationSelection === RegistrationSelection.NewCompany) {
		registrationPayload.company = {
			name: companyName,
			industry: companyIndustry,
			numEmployees: parseInt(numEmployees)
		};
	} else {
		registrationPayload.companyAccessCode = accessCode;
	}
	return registrationPayload;
}

function Register() {
	const [currentSubpage, setCurrentSubpage] = useState<CurrentPage>(CurrentPage.User);
	const [registrationSelection, setRegistrationSelection] = useState<RegistrationSelection>(0);
	const [registrationError, setRegistrationError] = useState<boolean>(false);
	const [registrationErrorMessage, setRegistrationErrorMessage] = useState<string>('');
	const classes = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);

	const updateSubpage = (to: CurrentPage) => {
		setCurrentSubpage(to);
	}

	const register = () => {
		const baseUrl = process.env.REACT_APP_DEPLOYMENT === 'production' ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
		const payload = prepareRegistrationData(registrationSelection);
		setLoading(true);
		fetch(baseUrl + '/register', {
			method: 'POST',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Connection': 'keep-alive',
			}),
			body: JSON.stringify(payload)
		})
			.then((response) => {
				if (response.status >= 500) {
					throw new Error('Server error');
				} else {
					return response.json();
				}
			})
			.then((response) => {
				if (response.status === 201) {
					alert('Success');
					navigate('/home');
				} else {
					setRegistrationError(true);
					setRegistrationErrorMessage(response.message);
				}
			})
			.catch(error => {
				setRegistrationError(true);
				setRegistrationErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<div className={classes.registerContainer}>
			<h1>Register for an account</h1>
			<div hidden={!registrationError}>
				<ErrorMessage message={registrationErrorMessage} />
			</div>
			<div hidden={currentSubpage !== CurrentPage.User}>
				<RegisterUserContainer />
			</div>
			<div hidden={currentSubpage !== CurrentPage.CompanySelect}>
				<CompantRegistrationType currentSelection={registrationSelection} selectionCallback={(r) => setRegistrationSelection(parseInt(r.target.value))} />
			</div>
			<div hidden={currentSubpage !== CurrentPage.CompanyCode}>
				<ExistingCompanyRegistration />
			</div>
			<div hidden={currentSubpage !== CurrentPage.CompanyCreate}>
				<NewCompanyRegistration />
			</div>

			<div className={classes.buttonContainer}>
				{currentSubpage > 0 &&
					<IBButton id='previousButton' title='Previous' onClick={() => updateSubpage(getPreviousPage(currentSubpage))} />
				}
				{currentSubpage < 20 &&
					<IBButton id='nextButton' title='Next' onClick={() => updateSubpage(getNextPage(currentSubpage, registrationSelection))} />
				}
				{currentSubpage >= 20 &&
					<IBButton id='registerButton' title='Register' onClick={() => register()} loading={loading} />
				}
			</div>
		</div>
	)
}

export default Register;