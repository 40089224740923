import { useState, useEffect } from 'react';
import useRequestResource from '../../hooks/useRequestResource';
import { Discussion } from '../../models/Discussion';
import { useNavigation, useSearchParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import TextArea from '../../UIElements/TextArea';
import { DiscussionComment } from '../../models/DiscussionComment';
import IBButton from '../../UIElements/IBButton';
import { User } from '../../authentication/models/User';
import { DiscussionCommentPayload } from '../../models/DiscussionCommentPayload';
import { useNavigate } from 'react-router-dom';
import LoadingView from '../../UIElements/LoadingView';

const useStyles = createUseStyles({
	discussionContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: '90%',
		margin: 'auto',
	},
	discussionDescriptionParagraph: {
		textAlign: 'justify'
	},
	discussionResponseTextArea: {
		height: '40vh'
	}
});

function DiscussionCommentSubview({ discussion, user }: { discussion: Discussion, user: User }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [payload, setPayload] = useState<DiscussionCommentPayload | undefined>(undefined);
	const { response: postResponse, loading: postLoading, error: postError } = useRequestResource({ appendingPath: '/bloom-comments', method: 'POST', body: payload });

	const classes = useStyles();
	const navigate = useNavigate();

	const submitComment = () => {
		const comment = (document.getElementById('discussionCommentTextArea') as HTMLInputElement).value;
		if (comment === undefined || comment === null || comment.trim() == '') {
			alert('You must enter a response to submit');
			return;
		}
		const payload: DiscussionCommentPayload = {
			content: comment,
			authorId: user.id,
			discussionId: discussion.id
		};
		setPayload(payload);
	}

	useEffect(() => {
		if (!postError && postResponse !== null && postResponse.status === 201) {
			navigate('/discussions');
		}
	});

	return (
		<div>
			{discussion &&
				<div className={classes.discussionContainer} hidden={postLoading}>
					<h1>{discussion.title}</h1>
					<h2>You have been invited by {discussion.creator.firstName} {discussion.creator.lastName} to collaborate!</h2>
					<h3>Here are the details:</h3>

					<p className={classes.discussionDescriptionParagraph}><strong>Problem</strong> - {discussion.problemDescription}</p>

					<p className={classes.discussionDescriptionParagraph}><strong>Problem Collab Output</strong> - {discussion.desiredOutput}</p>

					<p className={classes.discussionDescriptionParagraph}><strong>Collab Session will Conclude Once</strong> - {discussion.targetConsensus}% consensus or higher is achieved,
						or {discussion.maxIterations} iterations have been completed, or {discussion.maxTimeMin} minutes have elapsed.
					</p>

					<p className={classes.discussionDescriptionParagraph}><strong>Prompt:</strong> {discussion.questionPrompt}</p>
					<div className={classes.discussionResponseTextArea}>
						<TextArea
							id='discussionCommentTextArea'
							placeholder='Enter your response to the prompt here...' />
					</div>
					<div>
						<IBButton id='submitCommentBtn' title='Submit' onClick={() => submitComment()} />
					</div>
				</div>
			}
			<div hidden={!postLoading}>
				<LoadingView />
			</div>
		</div>
	)
}

export default DiscussionCommentSubview;