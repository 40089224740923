import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	errorMessage: {
		width: '75%',
		height: '50px',
		backgroundColor: 'rgb(243, 216, 218)',
		margin: 'auto',
		textAlign: 'center',
		verticalAlign: 'middle',
		lineHeight: '50px',
		fontWeight: 'bold'
	}
})

function ErrorMessage({ message }: { message: string }) {
	const classes = useStyles();
	return (
		<div className={classes.errorMessage}>
			Error: {message}
		</div>
	)
}

export default ErrorMessage;