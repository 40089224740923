import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Discussion } from '../../models/Discussion';
import { getCurrentTheme } from '../../config/getCurrentTheme';
import useRequestResource from '../../hooks/useRequestResource';
import { useSearchParams } from 'react-router-dom';
import { LLMSummary } from '../../models/LLMSummary';
import ReactMarkdown from 'react-markdown';


const theme = getCurrentTheme();

const useStyles = createUseStyles({
	discussionContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: '90%',
		margin: 'auto',
	},
	controlBtnContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginBottom: '1em',
	},
	discussionDescriptionParagraph: {
		textAlign: 'justify'
	},
	discussionResponseTextArea: {
		height: '30vh'
	},
	discussionPrompt: {
		textAlign: 'justify',
		backgroundColor: theme.primaryLight,
		padding: '20px',
		borderRadius: '20px',
		color: 'white',
		marginBottom: '2em'
	},
	discussionCommentContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: '100%',
		margin: 'auto'
	},
	discussionComment: {
		textAlign: 'justify',
		backgroundColor: theme.secondaryLight,
		padding: '20px',
		borderRadius: '20px',
		color: 'white',
		marginBottom: '2em'
	},
	discussionCommentHeader: {
		textAlign: 'start',
		fontStyle: 'italic'
	},
	llmSummary: {
		textAlign: 'justify',
		borderBottom: '2px solid black'
	}
});

function ClosedDiscussionSubview({ discussion }: { discussion: Discussion }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const { response: summaryResponse, loading: summaryLoading, error: summaryError } = useRequestResource({ appendingPath: '/summaries/' + searchParams.get('id'), method: 'GET', relocateOnFail: true });
	const [summaries, setSummaries] = useState<LLMSummary[] | null>(null);
	const classes = useStyles();

	useEffect(() => {
		try {
			setSummaries(summaryResponse.body as LLMSummary[]);
		} catch (error) {
			// Ignore
		}
	}, [summaryResponse]);

	return (
		<div className={classes.discussionContainer}>
			<h1>{discussion.title}</h1>
			Here are the details:

			<p className={classes.discussionDescriptionParagraph}><strong>Problem</strong> - {discussion.problemDescription}</p>

			<p className={classes.discussionDescriptionParagraph}><strong>Problem Collab Output</strong> - {discussion.desiredOutput}</p>

			<div>
				<div className={classes.discussionPrompt}><strong>Discussion Prompt:</strong> {discussion.questionPrompt}</div>
			</div>
			<h2>Team Insights Synthesis & Framework-based Feedback</h2>
			<div>
				{summaries && summaries.map(s => (
					<ReactMarkdown className={classes.llmSummary}>{s.summary}</ReactMarkdown>
				))}
				{(summaries === null || summaries.length === 0) &&
					<div>
						<h3>There are no summaries to display for this discussion</h3>
					</div>
				}
			</div>
		</div>
	)
}

export default ClosedDiscussionSubview;