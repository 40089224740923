import MainLayout from '../../UIElements/MainLayout';
import EditDiscussionSubview from './EditDiscussionSubview';

function EditDiscussionView() {
	return (
		<div>
			<MainLayout Subpage={<EditDiscussionSubview />} />
		</div>
	)
}

export default EditDiscussionView;