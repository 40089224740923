import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	homeContainer: {
		width: '90%',
		margin: 'auto'
	},
	noticeMessage: {
		textAlign: 'justify'
	}
});

function HomeSubview({ name }: { name: string | undefined }) {
	const classes = useStyles();
	return (
		<div className={classes.homeContainer}>
			<h1>Welcome, {name ?? ''}!</h1>
			<h2>Notice:</h2>
			<p className={classes.noticeMessage}>
				Welcome to IdeaBloom, we are excited that you are willing to try our platform. You are currently using a beta version
				of the product, and as such, not all features are currently available. For example, time limits, consensus, and iteration are
				not currently supported. That said, iteration can be simulated manually by first having the group complete their responses, generating a summary,
				and repeating the process. Additionally, it is possible that you will run into bugs during use. We ask that you kindly file a bug report at <a href='mailto:bug-report@ideabloom.io'>bug-report@ideabloom.io</a> should this occur. Should you have any questions, please don't hesitate
				to contact us at <a href='mailto:support@ideabloom.io'>support@ideabloom.io</a>. Again, we are excited you are here and look forward to working with you.
			</p>
		</div>
	)

}

export default HomeSubview;