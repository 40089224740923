import { createUseStyles } from 'react-jss';
import { getCurrentTheme } from '../config/getCurrentTheme';

const theme = getCurrentTheme();

const useStyles = createUseStyles({
	dataTable: {
		width: '90%',
		margin: 'auto',
		textAlign: 'center',
		borderCollapse: 'collapse',
		borderBottom: '2px solid black'
	},
	dataTableHeaderRow: {
		textAlign: 'center',
		lineHeight: '3em',
		backgroundColor: theme.secondaryDark,
		color: theme.primaryLight
	},
	dataTableRow: {
		userSelect: 'none',
		lineHeight: '3em',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'lightgray'
		},
		'&:hover $dataTableDeleteIcon': {
			visibility: 'visible'
		},
		MozUserSelect: 'none',
		WebkitUserSelect: 'none'
	},
	dataTableCell: {

	},
	dataTableBtnCell: {
		width: '40px'
	},
	dataTableDeleteIcon: {
		visibility: 'hidden',
		cursor: 'pointer'
	},
});

/**
 * 
 * @param {Object} An object containing a mapping of the data keys and their associated column titles. This should be in the same order of the columns.
 * @param {[Object]} data An array of data objects to populate the table.
 * @param {transformerMapping} transformerMapping An object containing keys and a transformer function to apply to each data item in the table. 
 * @returns 
 */
function DataTable({ columnMapping, data, transformerMapping, className, id, onRowClick, onDelete, onEdit }:
	{
		columnMapping: Record<string, any>,
		data: Record<any, any>[],
		transformerMapping?: Record<string, any>,
		className?: string,
		id: string,
		onRowClick?: (id: string) => void,
		onDelete?: (id: string) => void,
		onEdit?: (id: string) => void
	}) {

	const classes = useStyles();

	const handleRowClick = (id: string) => {
		if (onRowClick) {
			onRowClick(id);
		}
	}

	const handleDeleteClick = (id: string) => {
		if (onDelete) {
			onDelete(id);
		}
	}

	const handleEditClick = (id: string) => {
		if (onEdit) {
			onEdit(id);
		}
	}

	return (
		<table className={`${classes.dataTable} ${className}`}>
			<thead>
				<tr className={classes.dataTableHeaderRow}>
					{columnMapping &&
						Object.entries(columnMapping).map(([_, value], i) => (
							<th key={i}>{value}</th>
						))
					}
					{onDelete &&
						<th className={classes.dataTableBtnCell}></th>
					}
					{onEdit &&
						<th className={classes.dataTableBtnCell}></th>
					}
				</tr>
			</thead>
			<tbody>
				{data &&
					data.map((datum) => (
						<tr className={classes.dataTableRow} key={datum[id]}>
							{
								Object.entries(columnMapping).map(([key, _]) => (
									<td
										key={`${datum[id]}-cell-${key}`}
										onClick={() => handleRowClick(datum[id])}
										className={classes.dataTableCell}>{transformerMapping && transformerMapping[key] ? transformerMapping[key](datum[key]) : datum[key]}
									</td>
								))
							}
							{
								onDelete &&
								<td className={classes.dataTableBtnCell}
									key={`${datum[id]}-delete`}>
									<i id={`dataTableDeleteBtn-${datum[id]}`} className={classes.dataTableDeleteIcon} onClick={() => handleDeleteClick(datum[id])}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-trash3" viewBox="0 0 16 16">
											<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
										</svg>
									</i>
								</td>
							}
							{onEdit &&
								<td className={classes.dataTableBtnCell} key={`${datum[id]}-edit`}>
									<i id={`dataTableEditBtn-${datum[id]}`} className={classes.dataTableDeleteIcon} onClick={() => handleEditClick(datum[id])}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
											<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
										</svg>
									</i>
								</td>
							}
						</tr>
					))
				}
			</tbody>
		</table>
	)
}

export default DataTable;