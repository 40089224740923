import ErrorMessage from '../UIElements/ErrorMessage';
import IBButton from '../UIElements/IBButton';
import TextField from '../UIElements/TextField';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	newUserContainer: {
		width: '75%',
		margin: 'auto',
		textAlign: 'center'
	},
	buttonContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'& > button': {
			margin: '1em'
		}
	}
});

function InviteUserView({ onSubmit, onCancel, loading, error }: { onSubmit: (name: string, email: string) => void, onCancel: () => void, loading: boolean, error: boolean }) {
	const classes = useStyles();

	const handleSubmit = () => {
		const firstName = (document.getElementById('newUserFirstNameTextField') as HTMLInputElement).value;
		const email = (document.getElementById('newUserEmailTextField') as HTMLInputElement).value;
		onSubmit(firstName, email);
	}
	return (
		<div className={classes.newUserContainer}>
			<div hidden={!error}>
				<ErrorMessage message='An error occurred while processing your request' />
			</div>
			<h1>Invite a new user to your workspace</h1>
			<p>
				They will receive an email containing a unique code to use during registration at the email you provide. The code will be valid for 7 days.
			</p>
			<TextField id='newUserFirstNameTextField' type='text' placeholder='First Name' />
			<TextField id='newUserEmailTextField' type='email' placeholder='Their email address' />
			<div className={classes.buttonContainer}>
				<IBButton id='submitNewUserBtn' title='Submit' onClick={handleSubmit} loading={loading} />
				<IBButton id='cancelNewUserBtn' title='Cancel' onClick={onCancel} />
			</div>
		</div>
	)
}

export default InviteUserView;