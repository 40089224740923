import { useState, useEffect } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'; import { APIResponse } from '../networking/APIResponse';
import { Patch } from '../models/Patch';

export interface APIRequest {
	appendingPath: string;
	token?: string;
	method: string;
	body?: Record<any, any> | Patch[];
	relocateOnFail?: boolean;
	disableAuth?: boolean;
	isEnabled?: boolean;
}

function useRequestResource(request: APIRequest) {
	const [response, setResponse] = useState<any | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<any | null>(null);
	const authHeader = useAuthHeader();
	const baseUrl = process.env.REACT_APP_DEPLOYMENT === 'production' ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

	useEffect(() => {
		async function fetchData() {
			if (((request.method === 'POST' || request.method === 'PATCH') && (request.body === null || request.body === undefined)) || request.appendingPath === null) {
				// Early return if no payload is submitted with a POST, or if the appending path is null.
				return;
			}
			try {
				setLoading(true);
				setError(false);
				const requestObj: RequestInit = {
					method: request.method,
					mode: 'cors',
					headers: new Headers({
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `${request.disableAuth ? 'none' : authHeader ? authHeader : `Bearer ${request.token}`}`
					})
				};

				if (request.method === 'POST' || request.method === 'PATCH') {
					requestObj.body = JSON.stringify(request.body);
				}
				return new Promise((resolve, reject) => {
					fetch(baseUrl + request.appendingPath, requestObj)
						.then((response: Response) => {
							if (response.status >= 300) {
								setError(true);
							}
							if (response.status === 401) {
								if (request.relocateOnFail) {
									window.location.replace('/login');
								}
							} else if (response.status >= 500) {
								throw new Error('Server error: ' + 'res.message');
							}
							return response.json();
						})
						.then((response) => {
							const resp: APIResponse = {
								status: response.status,
								body: response.body,
								message: response.message
							};
							setResponse(resp);
							setLoading(false);
						})
						.catch((error) => {
							setResponse(null);
							setError(error);
							setLoading(false);
						});
				});
			} catch (error) {
				setError(error);
			}
		}
		if (request.isEnabled === undefined || request.isEnabled) {
			fetchData();
		}

	}, [request.appendingPath, request.token, request.method, request.body]);

	return { response, loading, error };
}

export default useRequestResource;