import { DNA } from 'react-loader-spinner';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	loadingView: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center'
	},
	loadingIndicator: {
		margin: 'auto'
	}
})

function LoadingView() {
	const classes = useStyles();

	return (
		<div className={classes.loadingView}>
			<DNA
				visible={true}
				height="200"
				width="200"
				ariaLabel="dna-loading"
				wrapperStyle={{}}
				wrapperClass={`dna-wrapper ${classes.loadingIndicator}`}
			/>
		</div>

	)
}

export default LoadingView;