import React from 'react';
import './App.css';
import Login from './authentication/Login';
import Register from './authentication/Register';
import Home from './home/Home';
import DiscussionView from './Discussion/DiscussionView';
import { Routes, Route, Navigate } from 'react-router-dom';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import CreateDiscussionView from './Discussion/Create Discussion/CreateDiscussionView';
import DiscussionCommentView from './Discussion/Comments/DiscussionCommentView';
import SettingsView from './settings/SettingsView';
import ResetPasswordView from './authentication/ResetPasswordView';
import EditDiscussionView from './Discussion/Edit Discussion/EditDiscussionView';

const store = createStore({
	authName: '_auth',
	authType: 'cookie',
	cookieDomain: window.location.hostname,
	cookieSecure: window.location.protocol === 'http:',
});

function App() {
	return (
		<div className="App">
			<AuthProvider store={store}>
				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='/register' element={<Register />} />
					<Route path='/reset-password' element={<ResetPasswordView />} />
					<Route element={<AuthOutlet fallbackPath='/login' />}>
						<Route path='/home' element={<Home />} />
					</Route>
					<Route path='/discussions' element={<DiscussionView />} />
					<Route path='/discussion' element={<DiscussionCommentView />} />
					<Route path='/edit-discussion' element={<EditDiscussionView />} />
					<Route path='/create-bloom' element={<CreateDiscussionView />} />
					<Route path='/settings' element={<SettingsView />} />
					<Route path='/' element={<Login />} />
				</Routes>
			</AuthProvider>

		</div>
	);
}

export default App;
