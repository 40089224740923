import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	successMessage: {
		width: '75%',
		height: '50px',
		backgroundColor: 'rgb(217, 236, 219)',
		margin: 'auto',
		textAlign: 'center',
		verticalAlign: 'middle',
		lineHeight: '50px',
		fontWeight: 'bold'
	}
})

function SuccessMessage({ message }: { message: string }) {
	const classes = useStyles();
	return (
		<div className={classes.successMessage}>
			{message}
		</div>
	)
}

export default SuccessMessage;