import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	registrationRadioContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	registrationOptionContainer: {
		width: '100%'
	}
});

function CompantRegistrationType({ currentSelection, selectionCallback }: { currentSelection: number, selectionCallback: (radio: any) => void }) {
	const classes = useStyles();

	return (
		<div className={classes.registrationRadioContainer}>
			<h2>Would you like to register with an existing company, or create a new one?</h2>
			<div className={classes.registrationOptionContainer}>
				<input type='radio' id='newCompany' name='companyRegistrationType' onChange={selectionCallback} value={10} checked={currentSelection === 10} />
				<label htmlFor='newCompany'>New Company</label>
			</div>
			<div className={classes.registrationOptionContainer}>
				<input type='radio' id='existingCompany' name='companyRegistrationType' onChange={selectionCallback} value={0} checked={currentSelection === 0} />
				<label htmlFor='existingCompany'>Existing Company</label>
			</div>
		</div>
	)
}

export default CompantRegistrationType;