import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import DataTable from '../UIElements/DataTable';
import useRequestResource from '../hooks/useRequestResource';
import ErrorMessage from '../UIElements/ErrorMessage';
import LoadingView from '../UIElements/LoadingView';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles({
	buttonHeader: {
		display: 'flex',
		flexBasis: 'row',
		alignItems: 'center'
	},
	buttonHeaderCell: {
		flexGrow: 1
	},
	buttonHeaderAddBtn: {
		cursor: 'pointer'
	}
});

function DiscussionSubpage() {
	const classes = useStyles();
	const { response, loading, error } = useRequestResource({ appendingPath: '/blooms', method: 'GET', relocateOnFail: true });
	const [deleteId, setDeleteId] = useState<string | undefined>(undefined);
	const [isDeleteEnabled, setIsDeleteEnabled] = useState<boolean>(false);
	const { response: deleteResponse, loading: deleteLoading, error: deleteError } = useRequestResource({ appendingPath: `/blooms/${deleteId}`, method: 'DELETE', relocateOnFail: true, isEnabled: isDeleteEnabled });
	const [tableData, setTableData] = useState<Record<any, any>[]>([]);
	const navigateHook = useNavigate();

	useEffect(() => {
		try {
			setTableData(response.body);
		} catch (error) {
			// Ignore
		}
	}, [response]);

	useEffect(() => {
		setDeleteId(undefined);
	}, [deleteResponse]);

	useEffect(() => {
		if (deleteResponse && deleteResponse.status === 200) {
			window.location.reload();
		}
	}, [deleteResponse]);

	const navigate = (to: string) => {
		navigateHook(to);
	}

	const deleteBloom = (id: string) => {
		if (window.confirm('Are you sure you want to delete this Bloom? This will delete all associated comments and is irreversible.')) {
			setDeleteId(id);
			setIsDeleteEnabled(true);
		}
	}

	return (
		<div>
			<div hidden={!error}>
				{
					response &&
					<ErrorMessage message={response.message} />
				}
			</div>
			<div hidden={!deleteError}>
				{deleteResponse &&
					<ErrorMessage message={deleteResponse.message} />
				}
			</div>
			<div hidden={!loading}>
				<LoadingView />
			</div>
			<div hidden={error}>
				<div className={classes.buttonHeader}>
					<div className={classes.buttonHeaderCell}></div>
					<h1 className={classes.buttonHeaderCell}>My Blooms</h1>
					<div className={classes.buttonHeaderCell}><BsFillPlusSquareFill onClick={() => navigate('/create-bloom')} className={classes.buttonHeaderAddBtn} size='2em' /></div>
				</div>
				<DataTable
					columnMapping={{ 'title': 'Title', 'maxIterations': 'Max Iterations', 'targetConsensus': 'Target Consensus', 'maxTimeMin': 'Max Time (min)' }}
					data={tableData}
					id='id'
					onRowClick={(id) => navigate('/discussion?id=' + id)}
					onDelete={(id) => deleteBloom(id)}
					onEdit={(id) => navigate('/edit-discussion?id=' + id)}
				/>
			</div>
		</div>

	)
}

export default DiscussionSubpage;