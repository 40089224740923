interface Patch {
	op: string;
	path: string;
	value: any;
}

export class PatchBuilder {
	patches: Record<string, Patch>;

	constructor() {
		this.patches = {};
	}

	addPatch(path: string, newValue: any): void {
		let patch: Patch = {
			op: 'replace',
			path: this.#generatePath(path),
			value: newValue
		};
		this.patches[path] = patch;
	}

	#generatePath(path: string): string {
		/// The path must start with a '/'. If it already does, leave it alone. Otherwise add it to the start.
		if (path.startsWith('/')) {
			return path;
		} else {
			return '/' + path;
		}
	}

	deletePatch(path: string): void {
		delete this.patches[path];
	}

	buildPatch(): Patch[] {
		let patch = [];
		for (const [key, value] of Object.entries(this.patches)) {
			patch.push(value);
		}
		return patch;
	}

}