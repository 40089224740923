import useRequestResource from '../hooks/useRequestResource';
import LoadingView from '../UIElements/LoadingView';
import MainLayout from '../UIElements/MainLayout';
import HomeSubview from './HomeSubview';

function Home() {
	const { response, loading, error } = useRequestResource({ appendingPath: '/user', method: 'GET', relocateOnFail: true });

	return (
		<div>
			<div hidden={!loading}>
				<LoadingView />
			</div>
			<div hidden={loading}>
				<MainLayout Subpage={<HomeSubview name={response && response.body.firstName} />} />
			</div>
		</div>
	)
}

export default Home;