import { getCurrentTheme } from '../config/getCurrentTheme';
import { Theme } from '../config/theme';
import UIProps from './UIProps';
import { createUseStyles } from 'react-jss';
import { RotatingLines } from 'react-loader-spinner';

const theme: Theme = getCurrentTheme();

const useStyles = createUseStyles({
	ibButton: {
		width: '200px',
		height: '50px',
		borderRadius: '10px',
		border: 'none',
		backgroundColor: theme.secondaryLight,
		color: 'white',
		boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.25)',
		'&:active': {
			backgroundColor: theme.secondaryDark,
			boxShadow: 'inset 5px 5px 10px rgba(0, 0, 0, 0.25)'
		},
		'&:disabled': {
			backgroundColor: theme.secondaryDark,
			boxShadow: 'inset 5px 5px 10px rgba(0, 0, 0, 0.25)'
		}
	}
});

interface IBButtonProps extends UIProps {
	title: string;
	onClick: (event: React.MouseEvent<HTMLElement>) => void;
	loading?: boolean;
}

function IBButton(props: IBButtonProps) {
	const classes = useStyles();

	return (
		<button
			className={classes.ibButton}
			id={props.id}
			onClick={props.onClick}
			disabled={props.loading ? true : false}
		>
			{!props.loading && props.title}
			{props.loading && <RotatingLines width='15%' strokeColor={theme.primaryLight} />}
		</button>
	)
}

export default IBButton;