import { useState, useEffect } from 'react';
import { Discussion } from '../../models/Discussion';
import useRequestResource from '../../hooks/useRequestResource';
import MainLayout from '../../UIElements/MainLayout';
import DiscussionCommentSubview from './DiscussionCommentSubview';
import { useSearchParams } from 'react-router-dom';
import { User } from '../../authentication/models/User';
import DiscussionEditorCommentSubview from '../DiscussionEditorCommentSubview';
import ClosedDiscussionSubview from './ClosedDiscussionSubview';

function DiscussionCommentView() {
	const [searchParams, setSearchParams] = useSearchParams();
	const { response: userResponse, loading: userLoading, error: userError } = useRequestResource({ appendingPath: '/user', method: 'GET', relocateOnFail: true });
	const [user, setUser] = useState<User | null>(null);
	const { response: discussionResponse, loading: discussionLoading, error: discussionError } = useRequestResource({ appendingPath: '/blooms?id=' + searchParams.get('id'), method: 'GET', relocateOnFail: true });
	const [discussion, setDiscussion] = useState<Discussion | null>(null);

	useEffect(() => {
		try {
			setDiscussion(discussionResponse.body as Discussion);
		} catch (error) {
			// Ignore
		}
	}, [discussionResponse]);

	useEffect(() => {
		try {
			setUser(userResponse.body as User);
		} catch (error) {
			// Ignore
		}
	}, [userResponse]);

	return (
		<div>
			{discussion && user && discussion.creator.username !== user.username && !discussion.closed &&
				<MainLayout Subpage={<DiscussionCommentSubview discussion={discussion} user={user} />} />
			}
			{discussion && user && discussion.creator.username === user.username &&
				<MainLayout Subpage={<DiscussionEditorCommentSubview discussion={discussion} />} />
			}
			{discussion && user && discussion.creator.username !== user.username && discussion.closed &&
				<MainLayout Subpage={<ClosedDiscussionSubview discussion={discussion} />} />
			}
		</div>

	)

}

export default DiscussionCommentView;