import { createUseStyles } from 'react-jss';
import UIProps from './UIProps';

const useStyles = createUseStyles({
	textField: {
		display: 'block',
		width: '100%',
		borderRadius: '10px',
		marginBottom: '1em',
		marginTop: '0.5em',
		padding: '1em',
		border: '1px solid black'
	}
});

export interface TextFieldProps extends UIProps {
	type: string;
	placeholder: string;
	onChange?: (value: any) => void;
	defaultValue?: string;
}

function TextField(props: TextFieldProps) {
	const classes = useStyles();

	const handleOnChange = (e: any) => {
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	}

	return (
		<input
			className={classes.textField}
			id={props.id}
			type={props.type}
			placeholder={props.placeholder}
			onChange={handleOnChange}
			defaultValue={props.defaultValue}
		/>
	);
}

export default TextField;