import React, { useEffect, useState } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { createUseStyles } from 'react-jss';
import TextField from '../UIElements/TextField';
import IBButton from '../UIElements/IBButton';
import useRequestResource from '../hooks/useRequestResource';
import { useNavigate, NavLink } from 'react-router-dom';
import { Credentials } from './Credentials';
import ErrorMessage from '../UIElements/ErrorMessage';

const useStyles = createUseStyles({
	loginPanel: {
		width: '50%',
		margin: 'auto'
	},
	navLink: {
		display: 'block',
		marginTop: '1em'
	}
});

function Login() {
	const classes = useStyles();
	const signIn = useSignIn();
	const navigate = useNavigate();
	const [credentials, setCredentials] = useState<any | null>(null);
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const handleLogin = () => {
		const username = (document.getElementById('usernameLoginEntry') as HTMLInputElement).value ?? "";
		const password = (document.getElementById('passwordLoginEntry') as HTMLInputElement).value ?? "";
		const baseUrl = process.env.REACT_APP_DEPLOYMENT === 'production' ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
		const credentials: Credentials = {
			username: username,
			password: password
		};
		setLoading(true);
		fetch(baseUrl + '/login', {
			method: 'POST',
			mode: 'cors',
			headers: new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Connection': 'keep-alive'
			}),
			body: JSON.stringify(credentials)
		})
			.then(response => {
				console.log(response);
				try {
					return response.json();
				} catch (err) {
					throw new Error('Server error');
				}
			})
			.then(response => {
				if (response.status === 200 && signIn({
					auth: {
						token: response.body.token,
						type: 'Bearer'
					}
				})) {
					navigate('/home');
				} else {
					setError(true);
					setErrorMessage(response.message);
				}
			})
			.catch(error => {
				setError(true);
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<div>
			<h1>Welcome to IdeaBloom</h1>
			<h2><i>Your leadership booms when your team's ideas Bloom!</i></h2>
			{error &&
				<ErrorMessage message={errorMessage} />
			}
			<div className={classes.loginPanel}>
				<TextField id='usernameLoginEntry' type='text' placeholder='Username' />
				<TextField id='passwordLoginEntry' type='password' placeholder='Password' />
				<IBButton id='loginButton' title='Login' onClick={handleLogin} loading={loading} />
				<NavLink className={classes.navLink} to='/register'>Don't have an account? Register here.</NavLink>
				<NavLink className={classes.navLink} to='/reset-password'>Forgot your password?</NavLink>
			</div>
		</div>
	);
}

export default Login;