import { useState, useEffect } from 'react';
import { Discussion } from '../models/Discussion';
import { DiscussionComment } from '../models/DiscussionComment';
import { createUseStyles } from 'react-jss';
import useRequestResource from '../hooks/useRequestResource';
import { useSearchParams } from 'react-router-dom';
import { getCurrentTheme } from '../config/getCurrentTheme';
import IBButton from '../UIElements/IBButton';
import { LLMSummary } from '../models/LLMSummary';
import ReactMarkdown from 'react-markdown';
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import ErrorMessage from '../UIElements/ErrorMessage';



const theme = getCurrentTheme();

const useStyles = createUseStyles({
	discussionContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: '90%',
		margin: 'auto',
	},
	controlBtnContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginBottom: '1em',
	},
	discussionDescriptionParagraph: {
		textAlign: 'justify'
	},
	discussionResponseTextArea: {
		height: '30vh'
	},
	discussionPrompt: {
		textAlign: 'justify',
		backgroundColor: theme.primaryLight,
		padding: '20px',
		borderRadius: '20px',
		color: 'white',
		marginBottom: '2em'
	},
	discussionCommentContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: '100%',
		margin: 'auto'
	},
	discussionComment: {
		textAlign: 'justify',
		backgroundColor: theme.secondaryLight,
		padding: '20px',
		borderRadius: '20px',
		color: 'white',
		marginBottom: '2em'
	},
	discussionCommentHeader: {
		textAlign: 'start',
		fontStyle: 'italic'
	},
	llmSummary: {
		textAlign: 'justify',
		borderBottom: '2px solid black'
	},
	summaryExpandCollapseBtn: {
		cursor: 'pointer'
	}
});

const getDateFromTimestamp = (timestamp: string) => {
	return new Date(timestamp).toDateString();
}

interface CloseDiscussionPayload {
	op: string;
	path: string;
	value: boolean;
}

const generateCloseDiscussionPayload = (isClosed: boolean) => {
	return [{
		op: 'replace',
		path: '/closed',
		value: isClosed
	}] as CloseDiscussionPayload[];
}

const generateSummaryPayload = (discussionId: string) => {
	return {
		discussionId: discussionId
	}
}

const getErrorMessage = (response: any) => {
	if (response && response.body) {
		return response.body.message;
	}
	return 'Something went wrong...';
}

function DiscussionEditorCommentSubview({ discussion }: { discussion: Discussion }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [closePayload, setClosePayload] = useState<CloseDiscussionPayload[] | undefined>(undefined);
	const [summaryPayload, setSummaryPayload] = useState<Record<any, any> | undefined>(undefined);
	const { response: closeResponse, loading: closeLoading, error: closeError } = useRequestResource({ appendingPath: '/blooms/' + searchParams.get('id'), method: 'PATCH', body: closePayload });
	const { response: discussionComments, loading: commentsLoading, error: commentsError } = useRequestResource({ appendingPath: '/bloom-comments/' + searchParams.get('id'), method: 'GET', relocateOnFail: true });
	const { response: llmSummaryResponse, loading: llmSummaryLoading, error: llmSummaryError } = useRequestResource({ appendingPath: '/summaries/' + searchParams.get('id'), method: 'GET' });
	const { response: postSummaryResponse, loading: postSummaryLoading, error: postSummaryError } = useRequestResource({ appendingPath: '/summaries', method: 'POST', body: summaryPayload });
	const [comments, setComments] = useState<DiscussionComment[] | null>(null);
	const [summaries, setSummaries] = useState<LLMSummary[] | null>(null);
	const [isClosed, setIsClosed] = useState<boolean>(discussion.closed);
	const [showSummaries, setShowSummaries] = useState<boolean>(false);
	const [pageError, setPageError] = useState<boolean>(false);
	const [pageErrorMessage, setPageErrorMessage] = useState<string>('');

	const classes = useStyles();

	useEffect(() => {
		if (closeError) {
			setPageError(true);
			setPageErrorMessage(getErrorMessage(closeResponse));
		}
		if (commentsError) {
			setPageError(true);
			setPageErrorMessage(getErrorMessage(discussionComments));
		}
		if (llmSummaryError) {
			setPageError(true);
			setPageErrorMessage(getErrorMessage(llmSummaryResponse));
		}
		if (postSummaryError) {
			setPageError(true);
			setPageErrorMessage(getErrorMessage(postSummaryResponse));
		}
		if (!closeError && !commentsError && !llmSummaryError && !postSummaryError) {
			setPageError(false);
		}
	}, [closeError, commentsError, llmSummaryError, postSummaryError, closeResponse, discussionComments, llmSummaryResponse, postSummaryResponse]);

	useEffect(() => {
		try {
			setComments(discussionComments.body as DiscussionComment[]);
		} catch (error) {
			// Ignore
		}
	}, [discussionComments]);

	useEffect(() => {
		try {
			const patchResponse = closeResponse.body as Discussion;
			setIsClosed(patchResponse.closed);
		} catch (error) {
			// Do nothing
		}
	}, [closeResponse]);

	useEffect(() => {
		try {
			const llmSummaries = llmSummaryResponse.body as LLMSummary[];
			setSummaries(llmSummaries);
		} catch (error) {

		}
	}, [llmSummaryResponse]);

	const toggleDiscussionStatus = () => {
		if (isClosed) {
			setClosePayload(generateCloseDiscussionPayload(false));
		} else {
			setClosePayload(generateCloseDiscussionPayload(true));
		}
	}

	const generateSummaryBtnClicked = () => {
		const payload = generateSummaryPayload(discussion.id);
		setSummaryPayload(payload);
	}

	return (
		<div className={classes.discussionContainer}>
			<h1>{discussion.title}</h1>
			<div hidden={!pageError}>
				<ErrorMessage message={pageErrorMessage} />
			</div>
			<div className={classes.controlBtnContainer}>
				<IBButton id='closeDiscussionBtn' title={isClosed ? 'Open Discussion' : 'Close Discussion'} loading={closeLoading} onClick={() => toggleDiscussionStatus()} />
				<IBButton id='generateSummaryBtn' title='Generate Summary' loading={postSummaryLoading} onClick={() => generateSummaryBtnClicked()} />
			</div>
			Here are the details:

			<p className={classes.discussionDescriptionParagraph}><strong>Problem</strong> - {discussion.problemDescription}</p>

			<p className={classes.discussionDescriptionParagraph}><strong>Problem Collab Output</strong> - {discussion.desiredOutput}</p>

			<div>
				<div className={classes.discussionPrompt}><strong>Discussion Prompt:</strong> {discussion.questionPrompt}</div>
			</div>

			<h2>
				Team Insights Synthesis & Framework-based Feedback {showSummaries ? <BsCaretUpFill className={classes.summaryExpandCollapseBtn} onClick={() => setShowSummaries(false)} /> : <BsCaretDownFill className={classes.summaryExpandCollapseBtn} onClick={() => setShowSummaries(true)} />}
			</h2>

			<div hidden={!showSummaries}>
				{summaries && summaries.map(s => (
					<ReactMarkdown className={classes.llmSummary}>{s.summary}</ReactMarkdown>
				))}
			</div>

			<h2>Team Responses</h2>

			<div className={classes.discussionCommentContainer}>
				{comments && comments.map(disc => (
					<div>
						<p className={classes.discussionCommentHeader}>Response from {disc.authorUsername} on {getDateFromTimestamp(disc.createdAt)}</p>
						<div className={classes.discussionComment}>{disc.content}</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default DiscussionEditorCommentSubview;