import { createUseStyles } from 'react-jss';
import { BsKeyFill, BsFillPersonPlusFill } from "react-icons/bs";
import { getCurrentTheme } from '../config/getCurrentTheme';
import InviteUserView from './InviteUserView';
import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import useRequestResource from '../hooks/useRequestResource';
import { NewUserPayload } from '../models/NewUserPayload';
import ChangePasswordView from './ChangePasswordView';

const theme = getCurrentTheme();

const useStyles = createUseStyles({
	settingsContainer: {
		display: 'flex',
		width: '90%',
		margin: 'auto',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignContent: 'center',
	},
	card: {
		width: '30%',
		border: '1px solid black',
		borderRadius: '20px',
		margin: '1em',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '10px',
		backgroundColor: theme.secondaryLight,
		color: 'white',
		cursor: 'pointer',
		boxShadow: '3px 3px 3px gray;'
	},
	cardImage: {
		margin: 'auto',
	},
	overlay: {
		position: 'absolute',
		width: '50%',
		height: '50%',
		border: '1px solid black'
	}
});

const createChangePasswordPayload = (oldPassword: string, newPassword: string) => {
	return {
		oldPassword: oldPassword,
		newPassword: newPassword
	}
}

function SettingsMainSubview() {
	const classes = useStyles();
	const [showAddUser, setShowAddUser] = useState<boolean>(false);
	const [newUserPayload, setNewUserPayload] = useState<NewUserPayload | undefined>(undefined);
	const { response: addUserResponse, loading: addUserLoading, error: addUserError } = useRequestResource({ appendingPath: '/users/invite', method: 'POST', body: newUserPayload });
	const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
	const [changePasswordPayload, setChangePasswordPayload] = useState<Record<any, any> | undefined>(undefined);
	const { response: changePasswordResponse, loading: changePasswordLoading, error: changePasswordError } = useRequestResource({ appendingPath: '/users/change-password', method: 'PATCH', body: changePasswordPayload });
	const [changePasswordErrorMessage, setChangePasswordErrorMessage] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (!addUserLoading && !addUserError) {
			setShowAddUser(false);
			setNewUserPayload(undefined);
		}
	}, [addUserLoading, addUserError]);

	useEffect(() => {
		if (!changePasswordError && !changePasswordLoading) {
			setShowChangePassword(false);
			setChangePasswordPayload(undefined);
		}
	}, [changePasswordLoading, changePasswordError]);

	useEffect(() => {
		if (changePasswordError && changePasswordResponse && changePasswordResponse.message) {
			setChangePasswordErrorMessage(changePasswordResponse.message);
		}
	}, [changePasswordResponse, changePasswordError]);

	const submitNewUserRequest = (firstName: string, email: string) => {
		const payload: NewUserPayload = { firstName: firstName, email: email };
		setNewUserPayload(payload);
	}

	const submitChangePassword = (oldPassword: string, newPassword: string) => {
		const payload = createChangePasswordPayload(oldPassword, newPassword);
		setChangePasswordPayload(payload);
	}

	return (
		<div>
			<h1>Settings</h1>
			<div className={classes.settingsContainer}>
				<div className={classes.card} onClick={() => setShowAddUser(true)}>
					<BsFillPersonPlusFill size='50%' className={classes.cardImage} />
					Invite a user
				</div>
				<div className={classes.card} onClick={() => setShowChangePassword(true)}>
					<BsKeyFill size='50%' className={classes.cardImage} />
					Change your password
				</div>
				<Modal isOpen={showAddUser}>
					<InviteUserView
						onSubmit={(name, email) => submitNewUserRequest(name, email)}
						loading={addUserLoading}
						onCancel={() => setShowAddUser(false)}
						error={addUserError} />
				</Modal>
				<Modal isOpen={showChangePassword}>
					<ChangePasswordView
						onSubmit={(oldPassword, newPassword) => submitChangePassword(oldPassword, newPassword)}
						loading={changePasswordLoading}
						onCancel={() => setShowChangePassword(false)}
						error={changePasswordError}
						errorMessage={changePasswordErrorMessage} />
				</Modal>
			</div>
		</div>
	)
}

export default SettingsMainSubview;