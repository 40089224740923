import { createUseStyles } from 'react-jss';
import TextField from '../UIElements/TextField';
import IBButton from '../UIElements/IBButton';
import ErrorMessage from '../UIElements/ErrorMessage';

const useStyles = createUseStyles({
	changePasswordContainer: {
		textAlign: 'center',
		width: '75%',
		margin: 'auto'
	},
	buttonContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'& > button': {
			margin: '1em'
		}
	}
});

function ChangePasswordView({ onSubmit, onCancel, loading, error, errorMessage }:
	{ onSubmit: (oldPassword: string, newPassword: string) => void, onCancel: () => void, loading: boolean, error: boolean, errorMessage?: string }) {
	const classes = useStyles();

	const handleSubmit = () => {
		const oldPassword = (document.getElementById('oldPasswordTextField') as HTMLInputElement).value;
		const newPassword = (document.getElementById('newPasswordTextField') as HTMLInputElement).value;
		onSubmit(oldPassword, newPassword)
	}

	return (
		<div className={classes.changePasswordContainer}>
			<h1>Change your password</h1>
			<div hidden={!error}>
				<ErrorMessage message={errorMessage ? errorMessage : 'There was an error processing your request'} />
			</div>
			<TextField id='oldPasswordTextField' type='password' placeholder='Old Password' />
			<TextField id='newPasswordTextField' type='password' placeholder='New Password' />
			<div className={classes.buttonContainer}>
				<IBButton id='changePasswordSubmitBtn' title='Submit' onClick={handleSubmit} loading={loading} />
				<IBButton id='cancelChangePasswordBtn' title='Cancel' onClick={onCancel} />
			</div>
		</div>
	)
}

export default ChangePasswordView;