export interface Theme {
	primaryLight: string;
	secondaryLight: string;
	primaryDark: string;
	secondaryDark: string;
}

export const defaultTheme: Theme = {
	primaryLight: '#da7f20',
	secondaryLight: '#2b8681',
	primaryDark: '#d54433',
	secondaryDark: '#213944'
}