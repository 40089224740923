import React from 'react';
import { createUseStyles } from 'react-jss';
import { getCurrentTheme } from '../config/getCurrentTheme';
import { BsFillHouseDoorFill, BsChatFill, BsFillGearFill, BsBoxArrowInRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import chroma from 'chroma-js';
import useSignOut from 'react-auth-kit/hooks/useSignOut';


const theme = getCurrentTheme();

const useStyles = createUseStyles({
	mainLayout: {
		display: 'flex',
		flexDirection: 'row',
		height: '100vh',
		overflow: 'hidden'
	},
	sidebar: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: theme.primaryDark,
		width: '25%',
		height: '100vh',
		boxShadow: `inset 0px 0px 5px 5px ${chroma(theme.primaryDark).brighten()}`
	},
	content: {
		width: '75%',
		height: '100vh',
		overflowY: 'scroll'
	},
	sidebarRow: {
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '75px',
		width: '100%',
		margin: 'auto',
		cursor: 'pointer',
		color: 'whitesmoke',
		marginTop: '2em',
		'&:hover': {
			opacity: 0.5,
			backgroundColor: theme.primaryLight
		},
		'@media screen and (max-width: 700px)': {
			justifyContent: 'center'
		}
	},
	sidebarIcon: {
		flexGrow: 0,
		padding: '20px'
	},
	sidebarText: {
		flexGrow: 1,
		fontSize: '2em',
		'@media screen and (max-width: 900px)': {
			fontSize: '1.5em'
		},
		'@media screen and (max-width: 700px)': {
			display: 'none'
		}
	}
});

function MainLayout({ Subpage }: { Subpage: React.ReactNode }) {
	const classes = useStyles();
	const navigateHook = useNavigate();
	const signOut = useSignOut();

	const handleLogout = () => {
		signOut();
		navigate('/');
	}

	const navigate = (to: string) => {
		navigateHook(to);
	}

	return (
		<div className={classes.mainLayout}>
			<div className={classes.sidebar}>
				<div>
					<div className={classes.sidebarRow} onClick={() => navigate('/home')}>
						<div className={classes.sidebarIcon}>
							<BsFillHouseDoorFill size='3em' />
						</div>
						<div className={classes.sidebarText}>
							Home
						</div>
					</div>
					<div className={classes.sidebarRow} onClick={() => navigate('/discussions')}>
						<div className={classes.sidebarIcon}>
							<BsChatFill size='3em' />
						</div>
						<div className={classes.sidebarText}>
							Blooms
						</div>
					</div>
				</div>
				<div>
					<div className={classes.sidebarRow} onClick={() => navigate('/settings')}>
						<div className={classes.sidebarIcon}>
							<BsFillGearFill size='3em' />
						</div>
						<div className={classes.sidebarText}>
							Settings
						</div>
					</div>
					<div className={classes.sidebarRow} onClick={handleLogout}>
						<div className={classes.sidebarIcon}>
							<BsBoxArrowInRight size='3em' />
						</div>
						<div className={classes.sidebarText}>
							Logout
						</div>
					</div>
				</div>
			</div>
			<div className={classes.content}>
				{Subpage}
			</div>
		</div>
	)
}

export default MainLayout;