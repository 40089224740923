import { createUseStyles } from 'react-jss';
import useRequestResource from '../../hooks/useRequestResource';
import TextField from '../../UIElements/TextField';
import NameBadgeView from '../../UIElements/NameBadgeView';
import TextArea from '../../UIElements/TextArea';
import { useEffect, useState } from 'react';
import { User } from '../../authentication/models/User';
import IBButton from '../../UIElements/IBButton';
import { DiscussionPayload } from '../../models/DiscussionPayload';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles({
	createDiscussionContainer: {
		width: '90%',
		margin: 'auto',
		marginTop: '1em',
		paddingBottom: '1em',
		paddingTop: '1em',
		'& h2': {
			'@media screen and (max-width: 860px)': {
				fontSize: '1em'
			}
		}
	},
	nameBadge: {
		width: 'fit-content',
		marginTop: '2em'
	},
	inputSection: {
		display: 'block',
		width: '100%',
		height: '25vh',
		margin: 'auto',
		marginBottom: '2em'
	},
	endpointSection: {
		display: 'block',
		width: '90%',
		margin: 'auto',
		marginBottom: '2em'
	},
	endpointInputs: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	endpointLabel: {
		fontWeight: 'bold',
		marginRight: '0.5em'
	}
})

const createDiscussionPayload = () => {
	const discussionTitle = (document.getElementById('createBloomTitleTextField') as HTMLInputElement).value;
	const discussionDescription = (document.getElementById('createBloomDescriptionTextField') as HTMLInputElement).value;
	const discussionOutput = (document.getElementById('createBloomOutputTextField') as HTMLInputElement).value;
	const discussionQuestion = (document.getElementById('createBloomQuestionTextField') as HTMLInputElement).value;
	const discussionIterations = (document.getElementById('createDiscussionIterationsInput') as HTMLInputElement).value;
	const discussionConsensus = (document.getElementById('createDiscussionConsensusInput') as HTMLInputElement).value;
	const discussionMaxTime = (document.getElementById('createDiscussionTimeInput') as HTMLInputElement).value;

	const payload: DiscussionPayload = {
		title: discussionTitle,
		problemDescription: discussionDescription,
		desiredOutput: discussionOutput,
		questionPrompt: discussionQuestion,
		maxIterations: parseInt(discussionIterations),
		targetConsensus: parseInt(discussionConsensus),
		maxTimeMin: parseInt(discussionMaxTime)
	};
	return payload;
}

function CreateDiscussionSubview() {
	const classes = useStyles();
	const [payload, setPayload] = useState<DiscussionPayload | undefined>(undefined);
	const { response, loading, error } = useRequestResource({ appendingPath: '/user', method: 'GET' });
	const { response: postResponse, loading: postLoading, error: postError } = useRequestResource({ appendingPath: '/blooms', method: 'POST', body: payload })
	const { response: userResponse, loading: userLoading, error: userError } = useRequestResource({ appendingPath: '/company/users', method: 'GET', relocateOnFail: true });
	const [user, setUser] = useState<User | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (response && response.body) {
			const user = response.body as User;
			setUser(user);
		}
	}, [response]);

	useEffect(() => {
		if (postResponse && !postError) {
			navigate('/discussions');
		}
	}, [postResponse, postError, navigate]);

	const submitBloom = () => {
		try {
			setPayload(createDiscussionPayload());
		} catch (error) {
			alert('Could not create discussion. Please verify that all prompts have data');
		}
	}

	return (
		<div className={classes.createDiscussionContainer}>
			<h1>Create a new Bloom</h1>
			<TextField type='text' placeholder='Bloom Title' id='createBloomTitleTextField' />
			<div className={classes.nameBadge}>
				<NameBadgeView name={`${user?.firstName ?? ''} ${user?.lastName ?? ''} (Editor)`} />
			</div>
			<div className={classes.inputSection}>
				<h2>Define the Problem</h2>
				<TextArea
					id='createBloomDescriptionTextField'
					placeholder='Write a description defining the problem you and your team are facing...'
				/>
			</div>
			<div className={classes.inputSection}>
				<h2>Articulate Desired Collab Output</h2>
				<TextArea
					id='createBloomOutputTextField'
					placeholder='Succinctly describe what you would like the team to get out of this, so they can respond appropriately...'
				/>
			</div>
			<div className={classes.inputSection}>
				<h2>Craft Question in Support of this Desired Output</h2>
				<TextArea
					id='createBloomQuestionTextField'
					placeholder='Write a question to guide your teams responses...'
				/>
			</div>
			<div className={classes.endpointSection}>
				<h2>Define the session endpoints</h2>
				<div className={classes.endpointInputs}>
					<div>
						<label className={classes.endpointLabel} htmlFor='createDiscussionIterationsInput'>Iterations</label>
						<input type='number' defaultValue={1} id='createDiscussionIterationsInput' />
					</div>
					<div>
						<label className={classes.endpointLabel} htmlFor='createDiscussionConsensusInput'>Consensus</label>
						<input type='number' defaultValue={85} id='createDiscussionConsensusInput' />
					</div>
					<div>
						<label className={classes.endpointLabel} htmlFor='createDiscussionTimeInput'>Time (min)</label>
						<input type='number' defaultValue={60} id='createDiscussionTimeInput' />
					</div>
				</div>
			</div>
			<IBButton title='Create' onClick={() => submitBloom()} id='createDiscussionSubmitBtn' loading={postLoading} />
		</div>
	)
}

export default CreateDiscussionSubview;