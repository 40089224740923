import TextField from '../UIElements/TextField';

function RegisterUserContainer() {


	return (
		<div>
			<TextField id='registerEmailTextField' type='email' placeholder='Email' />
			<TextField id='registerUsernameTextField' type='text' placeholder='Username' />
			<TextField id='registerPasswordTextField' type='password' placeholder='Password' />
			<TextField id='registerFirstNameTextField' type='text' placeholder='First Name' />
			<TextField id='registerLastNameTextField' type='text' placeholder='Last Name' />
		</div>
	)
}

export default RegisterUserContainer;