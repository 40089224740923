import TextField from '../UIElements/TextField';

function NewCompanyRegistration() {

	return (
		<div>
			<h2>Register with a new company</h2>
			<TextField id='companyNameInput' type='text' placeholder='Company Name' />
			<TextField id='companyIndustryInput' type='text' placeholder='Industry' />
			<TextField id='companyNumEmployeesInput' type='text' placeholder='Number of Employees' />
		</div>
	)
}

export default NewCompanyRegistration;