import MainLayout from '../UIElements/MainLayout';
import DiscussionSubpage from './DiscussionSubpage';

function DiscussionView() {

	return (
		<div>
			<MainLayout Subpage={<DiscussionSubpage />} />
		</div>
	)

}

export default DiscussionView;